

























































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
@Component({
  name: 'StatusMonitoring',
  filters: {
    formatTimestamp(date: number | any) {
      return formatTimestamp(date)
    },
  },
})
export default class StatusMonitoring extends VueBase {
  private reflashFlag = false
  private healthData = {
    dongtai_openapi: {
      status: 0,
    },
    dongtai_engine: {
      status: 0,
    },
    oss: {
      status: 0,
    },
    engine_monitoring_indicators: [],
  }

  private cache = 1
  private cacheIo = false
  private cacheTime = '00:00'

  private cacheTimeChange(date: Date) {
    console.log(date)
  }

  private async getHealth() {
    this.loadingStart()
    const res = await this.services.setting.health()
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.healthData = res.data
  }
  private async reflash() {
    this.loadingStart()
    const res = await this.services.setting.ossHealth()
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.healthData.oss = res.data.oss
  }
  // 保持变更

  created() {
    this.getHealth()
  }
}
